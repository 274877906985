export const getTeamShortName = (teamId) => [
    'AFC',
    'ARS',
    'BRI',
    'BUR',
    'CHE',
    'CRY',
    'EVE',
    'HUD',
    'LEI',
    'LIV',
    'MAC',
    'MAN',
    'NEW',
    'SOU',
    'STO',
    'SWA',
    'TOT',
    'WAT',
    'WEB',
    'WES',
][teamId];